import {
    AdapterMoment,
    DateTimePicker,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    LocalizationProvider,
    MenuItem,
} from "@enerbit/base";
import { useCreateZeroEstimationContext } from "../../../../context/create-zero-estimation";
import { Controller } from "react-hook-form";
import { DATE_TIME_INPUT_FORMAT } from "../../../../common";
import moment from "moment";
import { datePickerStyles } from "../../../../styles";
import CustomTextField from "../../../../components/Inputs/CustomTextField";
import CustomSelect from "../../../../components/Inputs/CustomSelect";
import { useEstimationsState } from "../../../../store";

const MeterItem = () => {
    const { form } = useCreateZeroEstimationContext();
    const {
        control,
        formState: { errors },
        watch,
    } = form;

    const { meterModels } = useEstimationsState((state) => state);

    const validateErrors = (key: string) => {
        return errors?.meters && !watch(`meters.${key}` as any);
    };

    return (
        <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item md={6}>
                <InputLabel>Fecha Inicio</InputLabel>
                <Controller
                    control={control}
                    name="meters.start_at"
                    defaultValue=""
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <FormControl fullWidth>
                                <DateTimePicker
                                    {...field}
                                    format={DATE_TIME_INPUT_FORMAT}
                                    value={
                                        field.value ? moment(field.value) : null
                                    }
                                    maxDate={moment()}
                                    onChange={(value) =>
                                        field.onChange(
                                            value?.toISOString() || "",
                                        )
                                    }
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: validateErrors("start_at"),
                                            helperText:
                                                validateErrors("start_at") &&
                                                "Este campo es obligatorio",
                                            sx: datePickerStyles,
                                        },
                                    }}
                                />
                            </FormControl>
                        </LocalizationProvider>
                    )}
                />
            </Grid>
            <Grid item md={6}>
                <InputLabel>Fecha fin</InputLabel>
                <Controller
                    control={control}
                    name="meters.ended_at"
                    defaultValue=""
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <FormControl fullWidth>
                                <DateTimePicker
                                    {...field}
                                    format={DATE_TIME_INPUT_FORMAT}
                                    value={
                                        field.value ? moment(field.value) : null
                                    }
                                    maxDate={moment()}
                                    onChange={(value) =>
                                        field.onChange(
                                            value?.toISOString() || "",
                                        )
                                    }
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: validateErrors("ended_at"),
                                            helperText:
                                                validateErrors("ended_at") &&
                                                "Este campo es obligatorio",
                                            sx: datePickerStyles,
                                        },
                                    }}
                                />
                            </FormControl>
                        </LocalizationProvider>
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Medidor</InputLabel>
                <Controller
                    control={control}
                    name="meters.meter_serial"
                    render={({ field }) => (
                        <CustomTextField
                            {...field}
                            fullWidth
                            error={validateErrors("meter_serial")}
                            helperText={
                                validateErrors("meter_serial") &&
                                "Este campo es obligatorio"
                            }
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Modelo Medidor</InputLabel>
                <Controller
                    control={control}
                    name="meters.meter_model"
                    render={({ field }) => (
                        <CustomSelect
                            {...field}
                            fullWidth
                            defaultValue={""}
                            error={validateErrors("meter_model")}
                        >
                            {meterModels.map((m) => (
                                <MenuItem key={m.id} value={m.code}>
                                    {m.name}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    )}
                />
                {validateErrors("meter_model") && (
                    <FormHelperText error sx={{ mx: "14px" }}>
                        Este campo es obligatorio
                    </FormHelperText>
                )}
            </Grid>
        </Grid>
    );
};

export default MeterItem;

import {
    Typography,
    enerbitColors,
    Divider,
    Grid,
    Skeleton,
    Box,
    CustomAlert,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { useEstimationsState } from "../../../store";

const Information = () => {
    const { loading, estimationDetail, error } = useEstimationsState(
        (state) => state,
    );

    return (
        <>
            {loading && !estimationDetail && (
                <>
                    <Skeleton variant="rounded" height={"110px"} />
                    <Skeleton variant="text" width={150} sx={{ my: 2 }} />
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            <Skeleton
                                variant="rounded"
                                height={45}
                                width={300}
                            />
                            <Skeleton
                                variant="rounded"
                                height={45}
                                width={300}
                            />
                        </Box>
                    </Box>
                </>
            )}
            {!loading && !error && estimationDetail && (
                <SectionContainer>
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontSize: "20px",
                            fontWeight: 700,
                        }}
                    >
                        Información
                    </Typography>
                    <Divider sx={{ my: 1, mx: "-12px" }} />
                    <Grid container columnSpacing={2}>
                        <Grid item md={2.4}>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: enerbitColors.neutral.main,
                                }}
                            >
                                Serial medidor
                            </Typography>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[900],
                                    fontWeight: 700,
                                }}
                            >
                                {estimationDetail?.meterSerial}
                            </Typography>
                        </Grid>
                        <Grid item md={2.4}>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: enerbitColors.neutral.main,
                                }}
                            >
                                Modelo medidor
                            </Typography>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[900],
                                    fontWeight: 700,
                                }}
                            >
                                {estimationDetail?.meterModel}
                            </Typography>
                        </Grid>
                        <Grid item md={2.4}>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: enerbitColors.neutral.main,
                                }}
                            >
                                Razón estimación
                            </Typography>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[900],
                                    fontWeight: 700,
                                }}
                            >
                                {estimationDetail?.reason}
                            </Typography>
                        </Grid>
                        <Grid item md={2.4}>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: enerbitColors.neutral.main,
                                }}
                            >
                                Fecha de carga
                            </Typography>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[900],
                                    fontWeight: 700,
                                }}
                            >
                                {estimationDetail?.estimationDate}
                            </Typography>
                        </Grid>
                        <Grid item md={2.4}>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: enerbitColors.neutral.main,
                                }}
                            >
                                Rango estimaciones
                            </Typography>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[900],
                                    fontWeight: 700,
                                }}
                            >
                                {estimationDetail?.estimationRange}
                            </Typography>
                        </Grid>
                    </Grid>
                </SectionContainer>
            )}
            {!loading && error && (
                <CustomAlert
                    severity="error"
                    text={
                        "Error cargando la información general de la estimación."
                    }
                    onClose={() => {}}
                />
            )}
        </>
    );
};

export default Information;

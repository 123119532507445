import {
    Grid,
    InputLabel,
    MenuItem,
    FormHelperText,
    TextField,
    Box,
    CardPdf,
    DragZone,
    LoadingButton,
    Button,
    DownloadOutlinedIcon,
} from "@enerbit/base";
import { Controller } from "react-hook-form";
import CustomSelect from "../../../../components/Inputs/CustomSelect";
import { useCreateEstimationListContext } from "../../../../context/create-estimation-list";
import {
    EstimationQueryParamValues,
    ExcelMeasurements,
    ILoadEstimationList,
} from "../../../../models";
import { useEstimationsState } from "../../../../store";
import { DropEvent, FileRejection } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import moment from "moment";
import { estimationTypeQueryKey } from "../../../../common";
import { downloadCSVTemplate } from "../../../../helpers";

const LoadEstimationsForm = () => {
    const { reasonTypes, setEstimationsList, setEstimationsListData } =
        useEstimationsState((state) => state);
    const { form } = useCreateEstimationListContext();
    const {
        setValue,
        watch,
        getValues,
        formState: { errors },
        control,
        handleSubmit,
    } = form;
    const navigate = useNavigate();

    const onDrop = (
        acceptedFiles: File[],
        _fileRejections: FileRejection[],
        _event: DropEvent,
    ) => {
        setValue("file", acceptedFiles[0]);
    };

    const onClearFile = () => {
        setValue("file", undefined);
    };

    const convertStringToNumber = (value: string): number | string => {
        const convertedValue = value.replace(",", ".");
        return !isNaN(Number(convertedValue))
            ? parseFloat(convertedValue)
            : value;
    };

    const onSubmit = (data: ILoadEstimationList) => {
        if (data.file) {
            setEstimationsListData({
                observation: data.observations,
                reasonId: data.reason_type_id,
            });

            const reader = new FileReader();
            reader.onload = (e: any) => {
                const csvData = e.target.result;

                Papa.parse(csvData, {
                    header: true,
                    complete: (results) => {
                        const jsonData = results.data;

                        const groupedMeasurements: Record<
                            string,
                            Record<string, ExcelMeasurements[]>
                        > = {};

                        const formattedData = jsonData.map((row: any) => ({
                            ...row,
                            fecha: moment(row.fecha).format(
                                "YYYY-MM-DDTHH:mm:ssZ",
                            ),
                            energia_activa_importada: convertStringToNumber(
                                row.energia_activa_importada,
                            ),
                            energia_activa_exportada: convertStringToNumber(
                                row.energia_activa_exportada,
                            ),
                            energia_reactiva_importada: convertStringToNumber(
                                row.energia_reactiva_importada,
                            ),
                            energia_reactiva_exportada: convertStringToNumber(
                                row.energia_reactiva_exportada,
                            ),
                        }));

                        formattedData.forEach((item: any) => {
                            const key = `${item.serial}-${item.modelo}`;

                            if (!groupedMeasurements[key]) {
                                groupedMeasurements[key] = {};
                            }

                            const date = new Date(item.fecha);
                            const monthKey = `${date.getFullYear()}-${(
                                date.getMonth() + 1
                            )
                                .toString()
                                .padStart(2, "0")}`;

                            if (!groupedMeasurements[key][monthKey]) {
                                groupedMeasurements[key][monthKey] = [];
                            }

                            groupedMeasurements[key][monthKey].push(item);
                        });

                        Object.keys(groupedMeasurements).forEach((key) => {
                            const months = groupedMeasurements[key];
                            Object.keys(months).forEach((monthKey) => {
                                months[monthKey].sort(
                                    (a, b) =>
                                        new Date(a.fecha).getTime() -
                                        new Date(b.fecha).getTime(),
                                );
                            });
                        });

                        setEstimationsList(groupedMeasurements);
                        navigate(
                            `/results?${estimationTypeQueryKey}=${EstimationQueryParamValues.LIST}`,
                        );
                    },
                });
            };

            reader.readAsText(data.file);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item md={12}>
                    <InputLabel required>Razón de la estimación</InputLabel>
                    <Controller
                        control={control}
                        name="reason_type_id"
                        defaultValue=""
                        render={({ field }) => (
                            <CustomSelect
                                {...field}
                                fullWidth
                                error={!!errors.reason_type_id}
                            >
                                {reasonTypes.map((r) => (
                                    <MenuItem key={r.id} value={r.id}>
                                        {r.description}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        )}
                    />
                    {!!errors?.reason_type_id && (
                        <FormHelperText error sx={{ mx: "14px" }}>
                            {errors.reason_type_id.message}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item md={12}>
                    <InputLabel required>Observaciones</InputLabel>
                    <Controller
                        control={control}
                        name="observations"
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                multiline
                                rows={2}
                                error={!!errors.observations}
                                helperText={
                                    !!errors.observations &&
                                    errors.observations.message
                                }
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12}>
                    <Box mt={2}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <InputLabel required>Subir excel</InputLabel>
                            <Button
                                variant="text"
                                color="secondary"
                                onClick={() =>
                                    downloadCSVTemplate(
                                        [
                                            [
                                                "serial",
                                                "modelo",
                                                "fecha",
                                                "energia_activa_importada",
                                                "energia_activa_exportada",
                                                "energia_reactiva_importada",
                                                "energia_reactiva_exportada",
                                            ],
                                        ],
                                        "plantilla",
                                    )
                                }
                                startIcon={<DownloadOutlinedIcon />}
                            >
                                Descargar plantilla de excel
                            </Button>
                        </Box>
                        {!!errors?.file && errors.file.message && (
                            <FormHelperText error>
                                {errors.file.message}
                            </FormHelperText>
                        )}
                        {watch("file") && (
                            <CardPdf
                                fileInfo={getValues("file")}
                                onClearFile={onClearFile}
                                isSuccess={true}
                                progress={100}
                                typeFile={"xlsx"}
                                isClearFile={true}
                            />
                        )}
                        {!watch("file") && (
                            <DragZone typeFile="csv" onDrop={onDrop} />
                        )}
                    </Box>
                </Grid>
                <Grid item md={12}>
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        color="secondary"
                        type="submit"
                    >
                        Cargar estimaciones
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    );
};

export default LoadEstimationsForm;

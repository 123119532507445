import {
    Box,
    InputLabel,
    SearchIcon,
    FormControl,
    Button,
} from "@enerbit/base";
import CustomTextField from "../../../../components/Inputs/CustomTextField";
import { useEstimationsState } from "../../../../store";

const Filters = () => {
    const { listEstimations, searchFilters, setFilters, resetFilters } =
        useEstimationsState((state) => state);

    const handleSearch = async () => {
        await listEstimations({ page: 0 });
    };

    const handleReset = async () => {
        resetFilters();
        await listEstimations({ page: 0 });
    };
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box>
                    <InputLabel>Medidor</InputLabel>
                    <FormControl>
                        <CustomTextField
                            fullWidth
                            value={searchFilters.meter_serial}
                            onChange={(e) =>
                                setFilters("meter_serial", e.target.value)
                            }
                            sx={{ width: "295px" }}
                            InputProps={{
                                startAdornment: <SearchIcon />,
                            }}
                        />
                    </FormControl>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={handleSearch}
                    >
                        <SearchIcon />
                    </Button>
                </Box>
                <Box>
                    <Button variant="text" sx={{ mt: 2 }} onClick={handleReset}>
                        Limpiar filtros
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Filters;

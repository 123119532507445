import { Box, enerbitColors, Grid, Typography } from "@enerbit/base";
import StyledModal from "../../../components/container/styled-modal";
import { ExcelMeasurements } from "../../../models";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface Props {
    isOpen: boolean;
    handleClose: () => void;
    data: ExcelMeasurements[];
}

interface MeasurementKeys {
    energia_activa_exportada: number;
    energia_activa_importada: number;
    energia_reactiva_exportada: number;
    energia_reactiva_importada: number;
}

const accumulatesNames: Record<
    keyof MeasurementKeys,
    { label: string; units: string }
> = {
    energia_activa_exportada: {
        label: "Energía activa exportada",
        units: "kWh",
    },
    energia_activa_importada: {
        label: "Energía activa importada",
        units: "kWh",
    },
    energia_reactiva_exportada: {
        label: "Energía reactiva exportada",
        units: "kVArh",
    },
    energia_reactiva_importada: {
        label: "Energía reactiva importada",
        units: "kVArh",
    },
};

const AccumulatesModal = ({ isOpen, handleClose, data }: Props) => {
    const accumulateData = data.reduce<MeasurementKeys>(
        (acc, obj) => {
            return {
                energia_activa_exportada:
                    acc.energia_activa_exportada + obj.energia_activa_exportada,
                energia_activa_importada:
                    acc.energia_activa_importada + obj.energia_activa_importada,
                energia_reactiva_exportada:
                    acc.energia_reactiva_exportada +
                    obj.energia_reactiva_exportada,
                energia_reactiva_importada:
                    acc.energia_reactiva_importada +
                    obj.energia_reactiva_importada,
            };
        },
        {
            energia_activa_exportada: 0,
            energia_activa_importada: 0,
            energia_reactiva_exportada: 0,
            energia_reactiva_importada: 0,
        },
    );

    const roundValues = (value: number) => {
        return parseFloat(value.toFixed(2)).toLocaleString("es-Es");
    };

    return (
        <StyledModal
            open={isOpen}
            onClose={handleClose}
            title="Datos acumulados"
        >
            <Grid container rowSpacing={2} columnSpacing={2}>
                {Object.keys(accumulateData).map((key) => (
                    <Grid item key={key} xs={6}>
                        <Box
                            sx={{
                                background:
                                    "linear-gradient(90deg, #F9FAFB 0%, #F9F6FF 100%)",
                                padding: "12px",
                                borderRadius: "8px",
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: "#F2E8FF",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "44px",
                                    width: "44px",
                                    borderRadius: "50%",
                                }}
                            >
                                <AccessTimeIcon />
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: enerbitColors.neutral.main,
                                        fontSize: "18px",
                                    }}
                                >
                                    {
                                        accumulatesNames[
                                            key as keyof MeasurementKeys
                                        ].label
                                    }
                                </Typography>
                                <Typography
                                    sx={{
                                        color: enerbitColors.neutral[900],
                                        fontSize: "24px",
                                        fontWeight: 700,
                                    }}
                                >
                                    {`${roundValues(
                                        accumulateData[
                                            key as keyof MeasurementKeys
                                        ],
                                    )} ${
                                        accumulatesNames[
                                            key as keyof MeasurementKeys
                                        ].units
                                    }`}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </StyledModal>
    );
};

export default AccumulatesModal;
